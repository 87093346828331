@import '../../shared/settings.scss';

.grid-page {
    display: grid;
    grid-template-columns: calc(40% - 8px) calc(60% - 8px);
    gap: var(--gutter-size);

    padding: var(--gutter-size);
    background: var(--sys-color-light-surface-container);
    border-radius: 8px;

    @include respond-to(mobile) {
        border-radius: 0;
        display: block;
        padding: 16px 0;
    }

    .location-menu + & {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

.grid-page-left-wide {
    grid-template-columns: calc(60% - 8px) calc(40% - 8px);
}

.backoffice-container {
    @include respond-to(mobile) {
        .grid-page {
            padding: 0 var(--gutter-size) var(--gutter-size);
        }
    }
    .grid-page-left-wide {
        grid-template-columns: 1fr 450px;
    }
}

.grid-page-right-wide {
    grid-template-columns: 334px 1fr;
}

.grid-page-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .grid-block-holder {
        width: 100%;
        max-width: 700px;
    }
}

.grid-column {
    .grid-block-holder {
        margin-bottom: var(--gutter-size);

        &:last-child {
            margin-bottom: 0;
        }

        @include respond-to(mobile) {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    @include respond-to(mobile) {
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-swap-order {
        order: 2;
    }

    &-small {
        padding: 100px 0;
        width: 100%;
        max-width: 380px;
        @include respond-to(mobile) {
            padding: 0;
        }
        @include respond-to-min(380px) {
            .grid-block {
                border-radius: 8px;
            }
        }
    }
}

.grid-block-holder {
    @include respond-to(mobile) {
        margin-bottom: 8px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.grid-block {
    padding: var(--gutter-size);
    background: white;
    border-radius: 8px;
    box-sizing: border-box;

    @include respond-to(mobile) {
        border-radius: 0px;
    }

    h3 {
        margin: 0 0 16px;
        font-size: 20px;
        display: flex;
        align-items: center;

        button {
            display: flex;
            align-items: center;
            width: 100%;

            svg {
                fill: var(--text-grey);
            }
        }

        svg {
            transition: transform 300ms;
            transform: rotate(0.5turn);
            margin-left: auto;
        }
    }

    h4,
    h5 {
        font-size: 16px;
        font-weight: bold;
        margin: 16px 0;

        &:first-child {
            margin-top: 0;
        }
    }

    h4 {
        margin-bottom: 8px;
        & + p {
            margin-top: 8px;
        }
    }

    h5 {
        margin-bottom: 0px;
        & + p {
            margin-top: 0px;
        }
    }

    /* break super long words in user generated content */
    p {
        /* These are technically the same, but use both */
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;

        line-height: 24px;

        .btn-link-inline,
        a {
            color: var(--text-grey);
            text-decoration: underline;
            transition: color 300ms;

            &:hover {
                color: var(--brand);
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &-full-width {
        grid-column: 1/-1;
    }

    &-swap-order {
        order: 2;
    }
}

.grid-block-collapsed {
    h3 {
        margin: 0;

        svg {
            transform: rotate(1turn);
        }
    }
}

.grid-block-detail {
    background: #f9fafb;
    padding: var(--gutter-size);
    border-radius: 8px;
    font-size: 14px;

    &:only-child {
        margin-top: 0;
    }

    p:first-child {
        margin-top: 0;
    }

    hr {
        width: 100%;
        margin: var(--gutter-size) 0;
    }
}

.grid-block-buttons {
    margin-top: var(--gutter-size);
    display: flex;
    gap: var(--gutter-size);
    flex-direction: column;

    .btn {
        width: 100%;
    }
}

.grid-block-detail-center {
    text-align: center;
}

.grid-block-header {
    border-bottom: 1px solid var(--border);
    padding: var(--gutter-size);
    margin: var(--negative-gutter-size) var(--negative-gutter-size)
        var(--gutter-size);

    h3 {
        margin-bottom: 0;
    }
}

.grid-map.google-maps {
    height: calc(100vh - 248px);
    border-radius: 8px;
    margin: var(--negative-gutter-size);

    .google-places {
        margin: 16px;
        max-width: 300px;
    }

    // overflow-hidden border radius Safari fix
    mask-image: radial-gradient(black, black);

    @include respond-to(mobile) {
        border-radius: 0px;
        height: calc(100vh - 185px);

        margin: var(--gutter-size) var(--negative-gutter-size)
            var(--negative-gutter-size);
    }
}

.product-locations-nearby {
    color: var(--success);
    display: block;
    font-size: 14px;
    font-weight: normal;
}
